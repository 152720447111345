import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import Actions from './actions'
import navigation from 'containers/Navigation/reducer'

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const location = handleActions(
  {
    [Actions.RECEIVE_LOCATION]: (state, { payload: location }) => location
  },
  { name: '' }
)

const scrap = handleActions(
  {
    [Actions.RECEIVE_TOP]: (state, { payload: top }) => ({
      ...state,
      ...top,
      activeType: ''
    }),
    [Actions.SET_ACTIVE_TYPE]: (state, { payload: activeType }) => ({
      ...state,
      activeType
    }),
    [Actions.RECEIVE_PROJECTS]: (state, { payload: projects }) => ({
      ...state,
      ...projects
    })
  },
  { top: {}, activeType: {}, projects: [] }
)

export default combineReducers({
  init,
  config,
  location,
  scrap,
  navigation
})
