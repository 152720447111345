import moment from 'moment-timezone'
import heartbeat from 'lib/saga/heartbeat'
import { fork, call, put, select, takeLatest } from 'redux-saga/effects'
import { TYPE_MONTHS_TO_DATE } from 'containers/Navigation/const'
import {
  SET_NAVIGATION,
  configureNavigation,
  setNavigationDate
} from 'containers/Navigation/actions'
import { selectNavigationRangeDates } from 'containers/Navigation/selectors'
import api from 'lib/api/client'
import {
  selectTimezone,
  selectToken,
  selectLocation,
  selectActiveType,
  selectLatestPresentMonthKey
} from './selectors'
import Actions, {
  startInit,
  finishInit,
  receiveLocation,
  requestTop,
  receiveTop,
  requestProjects,
  receiveProjects
} from './actions'

export function * init () {
  yield put(startInit())

  const timezone = yield select(selectTimezone)
  yield put(configureNavigation(TYPE_MONTHS_TO_DATE))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))

  const token = yield select(selectToken)
  const id = window.location.pathname.split('/').slice(-2)[0]
  const location = yield call(api.get, `/locations/${id}`, { token })
  yield put(receiveLocation(location))

  yield call(scraps)

  yield put(finishInit())
}

export function * scraps () {
  yield put(requestTop())
  const token = yield select(selectToken)
  const { id } = yield select(selectLocation)
  const params = yield select(selectNavigationRangeDates)
  const data = yield call(api.get, `/oee/dashboard/location/${id}/top_scrap`, {
    token,
    params
  })

  yield put(receiveTop(data))
  yield call(projects)
}

export function * projects () {
  const latestMonthKey = yield select(selectLatestPresentMonthKey)

  if (!latestMonthKey) {
    return
  }

  yield put(requestProjects())
  const token = yield select(selectToken)
  const { id } = yield select(selectLocation)
  const activeType = yield select(selectActiveType)
  const params = {
    month: moment(latestMonthKey).format('YYYY-MM-DD'),
    scrap_description: activeType.label
  }
  const data = yield call(
    api.get,
    `/oee/dashboard/location/${id}/top_scrap_projects`,
    {
      token,
      params
    }
  )

  yield put(receiveProjects(data))
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'OEE Scrap Dashboard')
  }

  yield call(init)

  yield takeLatest(SET_NAVIGATION, scraps)
  yield takeLatest(Actions.SET_ACTIVE_TYPE, projects)
}
