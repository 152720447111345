import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { selectActiveType, selectProjects } from './selectors'
import ScrapTooltip from './ScrapTooltip'
import TopProjects from '../../reports/top/TopProjects'

const formatter = _intl => val => val

const mapStateToProps = state => ({
  data: selectProjects(state),
  type: selectActiveType(state),
  tooltipContent: ScrapTooltip,
  formatter
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(TopProjects)
