import { createSelector } from 'reselect'

export const selectTimezone = state => state.config.timezone
export const selectToken = state => state.config.token
export const selectLocation = state => state.location
export const selectLocationName = state => state.location.name
export const selectTop = state => state.scrap.top
export const selectProjects = state => state.scrap.projects

export const selectMaxTopValue = createSelector(
  selectTop,
  topScrap =>
    Math.max(
      ...Object.values(topScrap)
        .flat()
        .map(e => e.value)
    )
)

export const selectLatestPresentMonthKey = createSelector(
  selectTop,
  topScrap => {
    const keys = Object.keys(topScrap).map(e => parseInt(e))
    if (!keys.length) {
      return null
    }

    return Math.max(...keys).toString()
  }
)

export const selectScrapTypes = createSelector(
  selectTop,
  selectLatestPresentMonthKey,
  (topScrap, latestKey) => latestKey ? topScrap[latestKey] : []
)

const selectOriginalActiveType = state => state.scrap.activeType
export const selectActiveType = createSelector(
  selectOriginalActiveType,
  selectScrapTypes,
  (type, types) => type || types[0]
)
