import React from 'react'
import PropTypes from 'prop-types'
import Unit from 'components/Unit'

const ScrapTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const data = payload[0]
    const { symbol, label } = data.payload
    return (
      <div className='recharts-custom-tooltip'>
        <div className='recharts-custom-tooltip__label'>
          {symbol ? `${symbol} - ${label}` : `${label}`}
        </div>

        <div className='recharts-custom-tooltip__value'>
          <Unit value={data.value} unit='piece' />
        </div>
      </div>
    )
  }

  return null
}

ScrapTooltip.propTypes = {
  active: PropTypes.boolean,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ),
  label: PropTypes.string.isRequired
}

export default ScrapTooltip
