import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ScrapHeader from './ScrapHeader'
import ScrapTop from './ScrapTop'
import ScrapTypes from './ScrapTypes'
import ScrapProjects from './ScrapProjects'
import { selectLatestPresentMonthKey } from './selectors'

const Scrap = ({ displayDetails }) => (
  <React.Fragment>
    <ScrapHeader />
    <ScrapTop />
    {displayDetails && (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <ScrapTypes />
        <ScrapProjects />
      </div>
    )}
  </React.Fragment>
)

Scrap.propTypes = {
  displayDetails: PropTypes.any
}

const mapStateToProps = state => ({
  displayDetails: selectLatestPresentMonthKey(state)
})

export default connect(mapStateToProps)(Scrap)
