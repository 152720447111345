import React from 'react'
import { connect } from 'react-redux'
import {
  selectScrapTypes,
  selectActiveType,
  selectLatestPresentMonthKey
} from './selectors'
import { FormattedMessage } from 'react-intl'
import { setActiveType } from './actions'
import moment from 'moment'
import TopTypes from '../../reports/top/TopTypes'

const mapStateToProps = state => {
  const month = selectLatestPresentMonthKey(state)

  return {
    types: selectScrapTypes(state),
    active: selectActiveType(state),
    month,
    label: (
      <FormattedMessage
        id='oee.locationScrapTypes.title'
        values={{ month: moment(month).format('MMMM YYYY') }}
        defaultMessage='Scrap types from {month}'
      />
    )
  }
}

const mapDispatchToProps = {
  onTypeClick: setActiveType
}

export default connect(mapStateToProps, mapDispatchToProps)(TopTypes)
