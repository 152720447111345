import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts'
import { injectIntl, intlShape } from 'react-intl'

const BAR_SIZE = 25

const TopChart = ({ data, pz, maxValue, month, intl, tooltipContent, formatter }) => {
  const fmt = formatter(intl)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '33%',
        height: 700,
        fontFamily: 'monospace'
      }}
    >
      <div style={{ textAlign: 'center', justifySelf: 'center' }}>
        {moment(month, 'YYYYMMDD').format('MMMM YYYY')}
        { pz && ` (${Math.round(pz / 60)} ${intl.formatMessage({ id: 'hours' })})` }
      </div>
      <ResponsiveContainer>
        <ComposedChart
          height={700}
          data={data}
          barGap={0}
          syncId='breakdownsTop'
          margin={{ bottom: 300, left: 20 }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis
            dataKey='label'
            angle={90}
            textAnchor='start'
            dx={5}
            dy={5}
            interval={0}
          />
          <YAxis domain={[0, Math.ceil((maxValue * 1.2) / 50) * 50]} tickFormatter={fmt} />
          <Bar dataKey='value' fill='#00BB5E' barSize={BAR_SIZE}>
            {data.map(entry => (
              <Cell key={`${entry.month}-${entry.symbol}`} fill={entry.unplanned ? 'indigo' : 'red'} />
            ))}
          </Bar>
          <Tooltip content={tooltipContent} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

TopChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  maxValue: PropTypes.number.isRequired,
  month: PropTypes.string.isRequired,
  intl: intlShape,
  formatter: PropTypes.func,
  tooltipContent: PropTypes.any
}

export default injectIntl(TopChart)
